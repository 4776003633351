:root {
  --bg-primary-color: #fcfcfd;
  --bg-color: #fcfcfd;
  --card-color: #dddddd;
  --card-color-transparent: #ffffff;
  --line-color-primary: #45454599;
  --icon-color: #656565;
  --text-primary-color: #475467;
}
[data-theme="dark"] {
  --bg-primary-color: #181818;
  --bg-color: #181818;
  --card-color: #444444;
  --card-color-transparent: #44444499;
  --line-color-primary: #cecece99;
  --icon-color: #ffffff;
  --text-primary-color: #e3f2fd;
}
/* Page & Layout */
.page-background {
  margin: 0;
  height: calc(100vh - 69px);
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1; */

  /* padding: 20px; */

  background-color: var(--bg-primary-color);
  /* transition: all 0.5s ease; */
}

.bg-transition {
  background-color: var(--bg-color);
  transition: all 0.5s ease;
}

.base-example-bg {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: var(--bg-primary-color);
  margin: 5px;

  cursor: pointer;

  /* transition: all 0.5s ease; */
}
.overlay-bg {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  margin: auto;

  transition: all 0.5s ease;
}

/* Components */

.page-navbar {
  width: 100%;
  height: 69px;
  position: sticky;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* padding: 20px; */

  padding-left: 32px;
  padding-right: 32px;
  /* margin-bottom: 10px;
  border-radius: 10px; */

  background-color: var(--card-color-transparent);
  transition: all 0.5s;

  box-sizing: border-box;
  border-bottom: 1px solid #eaecf0;
}

.page-footer {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;

  background-color: var(--card-color-transparent);
  transition: all 0.5s ease;
}

.page-content {
  width: 100%;
  height: 100%;
  /* height: 100%; */
  background-color: var(--bg-primary-color);
  /* max-height: max-content; */
}

.agent-chat-card {
  width: 100%;
  height: 100%;

  /* padding: 20px; */
  /* border-radius: 12px; */

  background-color: var(--card-color-transparent);
  /* transition: all 0.5s; */
}

.sub-card {
  /* width: 100%; */
  /* height: 100%; */
  /* padding: 20px; */

  background-color: var(--card-color);
  /* transition: all 0.5s ease; */
}
.icon path {
  stroke: var(--icon-color);
}
.nav-text {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary-color);
}
.text-title {
  /* Text md/Semibold */
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/700 */
  color: #344054;
}
.text-subtitle {
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}
.text-content-title {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}
.text-content {
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 5px;
}
.text-primary {
  color: var(--text-primary-color);
  /* color: #f00; */
}

.send-button {
  /* _Button base */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 76px;
  height: 44px;

  background: #ebe9fe;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;

  /* Text md/Semibold */
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #7a5af8;
}
.summary-button {
  /* _Button base */
  cursor: pointer;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 100%;
  height: 44px;

  background: #f2f4f7;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;

  /* Text md/Semibold */
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #344054;
}
.danger-button {
  /* _Button base */
  cursor: pointer;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 100%;
  height: 44px;

  background: #fcefef;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;

  /* Text md/Semibold */
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #ff4545;
}
.left-chat-box {
  /* Input */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  max-width: 500px;
  overflow-wrap: anywhere;

  background: #f2f4f7;
  border-radius: 0px 8px 8px 8px;

  white-space: pre-line;

  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/900 */
  color: #101828;
}
.right-chat-box {
  /* Input */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  max-width: 500px;
  overflow-wrap: anywhere;

  background: #ebe9fe;
  border-radius: 8px 0px 8px 8px;

  white-space: pre-line;
  /* Text */
  /* Text md/Regular */
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #5925dc;
}
.chat-name {
  /* Text */
  height: 20px;
  /* Text sm/Medium */
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */
  color: #344054;
}
.content-card {
  cursor: pointer;
  width: 314px;

  /* width: 100%; */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 16px;
  gap: 16px;

  /* Gray/50 */
  background: #f9fafb;
  border-radius: 8px;
}
.card-title {
  /* _Badge base */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;

  /* Gray/100 */
  background: #f2f4f7;
  border-radius: 16px;
  /* Text */

  /* Text sm/Medium */
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;

  /* Gray/700 */
  color: #344054;
}

.card-sub-title {
  /* Text sm/Semibold */
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #344054;
}

.card-content {
  width: 275px;

  white-space: pre-line;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Gray/600 */
  color: #475467;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
